// app/routes.ts

const routes = {
  home: "",
  templates: "templates",
  plan: "user/plan", //hardcoded in watermark.tsx
  billing: "user/billing",
  account: "user/account",
  settings: "user/settings",
  auth: "auth",
  logout: "auth/logout",
  login: "auth/login",
  register: "auth/register",
  authCallback: "auth/callback",
  authWithX: "auth/oauth/x",
  authWithGoogle: "auth/oauth/google",
  cancelPlan: "payment/cancel",
  upgradeFromFree: "payment/subscribe",
  switchPlan: "payment/switch-plan",
  createVideo: "videos/create",
  videos: "videos/all",
  video: "videos/edit/:video_slug", //slug for better ux. !!also edit app/utils/checkIsOnEditorPage.ts!!
  deleteVideo: "videos/delete/:video_id", // id for better DB perf
  updateVideo: "videos/update/:video_id",
  cloneVideo: "videos/clone/:video_id",
  publishVideo: "videos/publish/:video_id",
  startRender: 'api/render/start',
  getRenderProgress: 'api/render/progress',
  createFromTemplate: "videos/create-from-template/:template_id",
};

type RouteParams = {
  video_slug?: string;
  video_id?: string | number; // Allow both string and number
  template_id?: string | number; // Allow both string and number
};

export function getRoute(
  routeName: keyof typeof routes,
  params: RouteParams = {}
): string {
  let path = "/";
  // if(process.env.NODE_ENV === 'production'){
  //   path += "/app/"
  // }else{
  //   path += "/"
  // }
  path += routes[routeName];

  // Replace route parameters
  if (params.video_slug) {
    path = path.replace(":video_slug", params.video_slug);
  }
  if (params.video_id) {
    path = path.replace(":video_id", params.video_id.toString()); // Convert to string if it's a number
  }
  if (params.template_id) {
    path = path.replace(":template_id", params.template_id.toString()); // Convert to string if it's a number
  }

  
  // console.log(path);
  
  return path;
}